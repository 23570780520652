export type ErrorProps = {
  statusCode: number
  title: string
  subheader: string
  buttonText: string
}

export const defaultErrorProps: Omit<ErrorProps, 'statusCode'> = {
  title: 'Algo parece estar quebrado...',
  subheader: 'Parece que você ficou um pouco perdido',
  buttonText: 'Voltar à página inicial',
}
